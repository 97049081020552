/* eslint-disable max-len */
import React, { useEffect,useMemo,useState } from 'react';
import PropTypes from 'prop-types';

import { EDIT_STATUS } from 'constants/index';
import { useParams, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import {
  Table,
  Button,
  IconInput,
  Select,
  TimePicker,
  Icon,
  Breadcrumbs,
  Loading,
  BsModal,
  OptionMenu,
  ResourceModal,
} from 'components'; //IconInput, Select,
import { UiTable, UiActionBox, UiflexBox } from 'styles';
import { useSetState } from 'utils/hooks/useSetState';
import { useSessions } from 'store/sessions';
import { useClass } from 'store/class';
import { useUser } from 'store/user';
import { getOrganizationSessionEduSubjectOptions } from 'services/api/organization/session';
import { useAlert } from 'utils/hooks/useAlert';
import { useDateInterval } from 'utils/hooks/useDateInterval';
import { SESSION_START_TIME_TYPE, PREVIEW_TIMESTAMP, } from 'constants/index';
import { getTime } from 'date-fns';
import { UiTableInfo } from 'styles';
import { 
  UiButtonBox, 
  UiStartTimeIcon,
  UiIconBox,
  UiIcon,
} from './SessionManagementTable.style';

/**
 * 課程列表
 */

const NOW = 'NOW';
const PROCESSING = 'PROCESSING';
const PAST = 'PAST';
const FUTURE = 'FUTURE';
const ALL = 'ALL';

const SESSION_START_TIME_TYPE_INFO = {
  [SESSION_START_TIME_TYPE.YET]: '尚未開始',
  [SESSION_START_TIME_TYPE.ALREADY]: '已結束',
  [SESSION_START_TIME_TYPE.PROCESSING]: '進行中'
};

const selectOptions = [
  {
    name: '今日課程',
    value: NOW
  },
  {
    name: '當前課程',
    value: PROCESSING
  },
  {
    name: '已上課程',
    value: PAST
  },
  {
    name: '未來課程',
    value: FUTURE
  },
  {
    name: '所有課程',
    value: ALL
  }
];

export const SessionManagementTable = ({
  noFilter = false,
  defaultDuration = ALL
}) => {
  const history = useHistory();
  const { setAlert } = useAlert();
  const [, { todayInterval, processingInterval, pastInterval, futureInterval, allInterval }] = useDateInterval();
  const { organizationId, classId } = useParams();
  const [{ sessions, sessionSearchParams, groupSessionSearchParams },
    {
      getOrganizationSessions,
      deleteOrganizationGroupSession,
      setSessionSearchParams,
      setGroupSessionSearchParams,
  }] = useSessions();
  const [{ classes }] = useClass();
  const [{ myOrganization }] = useUser();

  const [
    {
      sessionId,
      sessionName,
      groupName,
      hostName,
      educationalSystem,
      subject,
      hostMobileNumber,
      startAt,
      endAt,
      nowPage,
      rowsPage,
      goal,
      educationOptions,
      subjectMap,
      subjectOptions,
      condition,
      dateInterval,
      schema,
      isLoading,
      isOptionMenuOpen,
      isOpenResourceModal,
      menuSessionId,
      targetSessionId,
      targetTimeSpanId
    }, setState
  ] = useSetState({
    sessionId: '',
    sessionName: '',
    groupName: '',
    hostName: '',
    educationalSystem: '',
    subject: '',
    hostMobileNumber: '',
    startAt: null,
    endAt: null,
    nowPage: classId ? groupSessionSearchParams.nowPage : sessionSearchParams.nowPage,
    rowsPage: classId ? groupSessionSearchParams.rowsPage : sessionSearchParams.rowsPage,
    goal: classId ? groupSessionSearchParams.goal : sessionSearchParams.goal,
    educationOptions: [],
    subjectMap: null,
    subjectOptions: [],
    condition: classId ? groupSessionSearchParams.condition : sessionSearchParams.condition,
    dateInterval: classId ? groupSessionSearchParams.dateInterval : sessionSearchParams.dateInterval,
    schema: {},
    isLoading: true,
    isOptionMenuOpen: false,
    isOpenResourceModal: false,
    menuSessionId: '',
    targetSessionId:'',
    targetTimeSpanId:''
  });

  const [isOpen,setIsOpen] = useState(false);
  const [targetSession,setTargetSession] = useState('');
  const [targetClassId,setTargetClassId] = useState('');

  //麵包屑
  const BreadcrumbsList = [
    {
      name: '班級管理',
      link: `/organization/${organizationId}/class`,
      icon: 'school'
    },
    {
      name: `${classes.dataInfo.name}-課程總覽`,
      link: `/organization/${organizationId}/class/${classId}/customer`,
      icon: 'importContacts'
    }
  ];

  const defaultSchema = {
    groupName: {
      name: '班級名稱',
      defaultValue: ''
    },
    name: {
      name: '課程名稱',
      defaultValue: ''
    },
    subject:{
      name:'科目',
      defaultValue:'--'
    },
    sessionStatus:{
      name:'狀態',
      defaultValue:'--'
    },
    startTime: {
      name: '時間',
      defaultValue: ''
    },
    classTime: {
      name: '課程時數',
      defaultValue: ''
    },
    hostName: {
      name: '授課教師',
      defaultValue: ''
    },
    resourceId: {
      name: '上課資源',
      defaultValue: ''
    },
    attendance: {
      name: '出勤',
      defaultValue: '-'
    }
  };

  const selectInputOptions = [
    {
      name: '課程名稱',
      value: 'sessionName',
    },
    !classId &&
    {
      name: '班級名稱',
      value: 'groupName',
    },
    {
      name: '授課教師名稱',
      value: 'hostName'
    }

  ];

  const showMonitor = useMemo(()=>{
    if(myOrganization?.organization?.adminMonitorSessionSetting){
      return myOrganization.organization.adminMonitorSessionSetting === 'disallow'? false
      :true;
    }
    return false;
  },[myOrganization]);

  const resetFormData = (value) => {
    setState({
      sessionName: '',
      groupName: '',
      hostName: '',
      hostMobileNumber: '',
      startAt: '',
      rowsPage: 10,
      nowPage: 0,
      [goal]: value
    });
  };

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const onSubmitHandler = (value, key) => {
    if (key === 'condition') {
      if (goal === '') {
        setAlert('請選擇搜尋目標!', 'warning');
        return;
      } else {
        resetFormData(value);
      }
    }
    setState({
      [key]: value,
      nowPage: 0
    });
  };

  const educationChangeHandler = (value, key) => {
    console.log('education value', value);
    console.log('key', key);
    setState({
      educationalSystem: value,
      nowPage: 0
    });
  };

  const subjectChangeHandler = value => {
    console.log('subject value', value);
    setState({
      subject: value,
      nowPage: 0
    });
  };

  const onchangeHandler = (value, key) => {
    setState({
      [key]: value,
      nowPage: 0
    });
  };

  const getDateDuration = dateType => {
    switch (dateType) {
      case NOW:
        return { ...todayInterval() };
      case PAST:
        return { ...pastInterval() };
      case PROCESSING:
        return { ...processingInterval() };
      case FUTURE:
        return { ...futureInterval() };
      default:
      case ALL:
        return { ...allInterval() };
    }
  };

  const onSelectChangeHandler = selected => {
    const dateParams = getDateDuration(selected);
    setState({
      nowPage: 0,
      dateInterval: selected,
      ...dateParams
    });
  };

  const goCreateSession = () => {
    history.push(`/organization/${organizationId}/class/${classId}/session/${EDIT_STATUS.CREATE}`);
  };

  const fetchOrganizationSessionEduSubjectOptions = async () => {
    const { isSuccess, data } = await getOrganizationSessionEduSubjectOptions(organizationId);
    if (isSuccess) {
      const { educationalSystems, subjects } = data;
      const nextEducationalSystems = educationalSystems.map( item => ({...item, value: item.code, }));

      setState({
        educationOptions: nextEducationalSystems,
        subjectMap: subjects,
      });
    } else {
      setAlert('取得 學制/科目 列表失敗！', 'error');
    }
  };

  useEffect(() => {
    fetchOrganizationSessionEduSubjectOptions();
    if (classId) delete defaultSchema.groupName;
    setState({
      schema: defaultSchema
    });
  }, []);

  useEffect(() => {
    if (!educationalSystem) return;
    setState({
      subject: '',
      subjectOptions: subjectMap[educationalSystem].map(item => ({...item, value: item.code, })),
    });
  }, [educationalSystem]);

  const fetchSessions = async () => {
    setState({ isLoading: true });
    const dateParams = startAt === null || endAt === null 
      ? getDateDuration(sessionSearchParams.dateInterval) 
      : { startAt, endAt };

    const params = {
      [goal]: condition,
      hostMobileNumber,
      educationalSystem,
      subject,
      ...dateParams,
      nowPage,
      rowsPage
    };
    if(classId) delete params.groupName;
    await getOrganizationSessions(params);
    setState({ isLoading: false });
  };

  useEffect(() => {
    fetchSessions();
  }, [
    sessionName,
    groupName,
    hostName,
    hostMobileNumber,
    educationalSystem,
    subject,
    startAt,
    endAt,
    nowPage,
    rowsPage]);

  const getStartTimeType = (startAt, endAt) => {
    const now = new Date().getTime();
    if (now < startAt) return SESSION_START_TIME_TYPE.YET;
    if (now > endAt) return SESSION_START_TIME_TYPE.ALREADY;
    return SESSION_START_TIME_TYPE.PROCESSING;
  };

  // eslint-disable-next-line react/prop-types
  const StartTimeIcon = ({ children, type }) => (
    <UiStartTimeIcon type={type}>
      <Icon
        className="icon"
        name="info"
        size="small"
        title={SESSION_START_TIME_TYPE_INFO[type]}
        haveBg={false}
      />
      <span>{children}</span>
    </UiStartTimeIcon>
  );

  const formatSessionsData = data => data.map(item => {
    const startTimeType = getStartTimeType(item.startAt, item.endAt);
    return {
      ...item,
      resourceId: item?.resourceId || item?.resources?.join(', '),  // 上課資源
      classTime: ((item.endAt - item.startAt) / 60 / 1000) + '分',
      startTime: (
        <StartTimeIcon type={startTimeType}>
          {format(new Date(item.startAt), 'yyyy-MM-dd HH:mm')} ~ {format(new Date(item.endAt), 'HH:mm')}
        </StartTimeIcon>
      ),
      attendance:(
        <>
          <div>{item.hostAttendance ? item.hostName :''}</div>
          {item.hostAttendance?.checkInAt ? format(new Date(item.hostAttendance?.checkInAt), 'HH:mm') : '-'}
          {' / '}
          {item.hostAttendance?.checkOutAt ? format(new Date(item.hostAttendance?.checkOutAt), 'HH:mm'): '-'}
        </>
      ),
    };
  });

  // eslint-disable-next-line react/prop-types
  const ActionComponents = ({ params: {
    id: sessionId,
    groupId,
    groupName,
    hostName,
    timeSpanId,
    resourceId,
    endAt,
    startAt,
    recordingStatus,
    name,
    type,
  } }) => {
    const isExpired = (getTime(new Date(endAt)) < getTime(new Date())) ? true : false;
    const startTimeType = getStartTimeType(startAt, endAt);
    const ButtonClick = () => {
      setState({ isOpenResourceModal: true, sessionId });
    };

    const onViewSession = () => {
      const url = `${process.env.REACT_APP_ONEBOARD_BS_DOMAIN}/player/${sessionId}`;
      window.open(url);
    };

    /* OptionMenu 資料 */
    const optionMenuData = [
      {
        label: '複製課程',
        onClick: () =>
          history.push(
            `/organization/${organizationId}/class/${groupId}/session/${sessionId}/${EDIT_STATUS.COPY}`
          )
      },
      {
        label: '刪除課程',
        onClick: () => modalToggle(name, groupId, sessionId, timeSpanId)
      },
    ];

    /* OptionMenu 資料 - 編輯課程 */
    const editObject = {
      label: '編輯課程',
      onClick: () =>
        history.push(
          `/organization/${organizationId}/class/${groupId}/session/${sessionId}/${EDIT_STATUS.EDIT}`
        )
    };

    // 將編輯課程插入至 optionMenuData
    !isExpired && optionMenuData.unshift(editObject);

    /* 判斷現在是否為預課時間 */
    const isPreviewTime = () => {
      const nowTimestamp = Date.now();  // 現在時間
      const previewStartTime = startAt - PREVIEW_TIMESTAMP;  // 預課開始時間

      if(nowTimestamp >= previewStartTime) return true;
    };

    return (
      <UiIconBox>
        {/* {
          !isExpired && (
            <Icon
              title="編輯"
              onClick={() =>
                history.push(
                  `/organization/${organizationId}/class/${groupId}/session/${sessionId}/${EDIT_STATUS.EDIT}`
                )}
              name='edit'
            />
          )
        } */}
        {
          type === 'normal' &&startTimeType === SESSION_START_TIME_TYPE.PROCESSING && showMonitor &&
          <Icon
          title="監課"
          onClick={ButtonClick}
          name='visibility' />
        }
        {/* <Icon
          icolor={type === 'interactive' ? '#ccc':'#fff'}
          title="課程報表"
          onClick={() =>{
            if(type === 'interactive') return;
            history.push(
              `/organization/${organizationId}/session/${sessionId}/timespan/${timeSpanId}/summary`
            );}}
          name='artTrack' /> */}
        {
          (type === 'interactive' || type === 'video' || type === 'collaborative') &&
          <Icon
            title="觀課"
            name="visibility"
            onClick={onViewSession}
          />
        }
        {((type === 'interactive' || type === 'video' || type === 'collaborative') && isPreviewTime()) && <Icon
          title="課堂報告"
          name='assessment'
          onClick={ () => {
            classId ?
             history.push({
              pathname: `/organization/${organizationId}/class/${groupId}/session/${sessionId}/timespan/${timeSpanId}/report`,
              state: {
                className: groupName,
                groupId,
                resourceId,
                sessionType: type,
              }
             }) :
             history.push({
              pathname: `/organization/${organizationId}/class/session/${sessionId}/timespan/${timeSpanId}/report`,
              state: {
                className: groupName,
                groupId,
                resourceId,
                sessionType: type,
              }
             });
          }}
        />}
        {/* <Icon
          title="複製課程"
          name='fileCopy'
          onClick={() =>
            history.push(
              `/organization/${organizationId}/class/${groupId}/session/${sessionId}/${EDIT_STATUS.COPY}`
            )}
          />
        <Icon
          title="刪除"
          onClick={() => modalToggle(name,groupId,sessionId,timeSpanId)}
          name='delete' /> */}
        <UiIcon>
          <Icon
            title="更多"
            name="moreVert"
            haveBg={false}
            icolor="#242C3F"
            onClick={() => optionMenuToggle(sessionId)}
          />
          {
            (
              menuSessionId === sessionId
              && isOptionMenuOpen
            ) 
            && <OptionMenu data={optionMenuData}/>
          }
        </UiIcon>
      </UiIconBox>
    );
  };

  const modalToggle = (name,targetClass,sessionId,timeSpanId) => {
    name && setTargetSession(name);
    targetClass && setTargetClassId(targetClass);
    sessionId && setState({
      targetSessionId:sessionId,
      targetTimeSpanId:timeSpanId
    });
    setIsOpen(!isOpen);
  };

  /* 更多按鈕，開關 OptionMenu */
  const optionMenuToggle = (sessionId) => {
    setState({ 
      isOptionMenuOpen: !isOptionMenuOpen,
      menuSessionId: sessionId,
     });
  };

  const onDeleteSession = async () => {
    setState({isLoading:true});
    const isSuccess = await deleteOrganizationGroupSession(targetClassId,targetSessionId,targetTimeSpanId);
    setState({isLoading:false});
    modalToggle();
    if(isSuccess) await fetchSessions();
   };

  /* click 課程教材彈窗 - 確認按鈕 */
  const clickResourceModalOkHandler = resourceValue => {
    const classRoomUrl = `${process.env.REACT_APP_ONEBOOK_DOMAIN}/${resourceValue}`;
    window.open(classRoomUrl, '_blank');  // 跳轉至 oneBook 教材
    setState({ isOpenResourceModal: false });
  };

  /* click 課程教材彈窗 - 取消按鈕 */
  const clickResourceModalCancelHandler = () => {
    setState({ isOpenResourceModal: false});
  };

   useEffect(()=>{
      const payload = {
        goal: goal || '',
        condition: condition || '',
        dateInterval: dateInterval || ALL,
        nowPage: nowPage || 0,
        rowsPage: rowsPage || 10,
      };
      classId ? setGroupSessionSearchParams(payload) : setSessionSearchParams(payload);
   },[goal,condition,dateInterval,nowPage,rowsPage]);

  return (
    <UiTable>
      { /* 課程教材 彈窗 */
        isOpenResourceModal &&
          <ResourceModal
            sessionId={sessionId}
            isOpenResourceModal={isOpenResourceModal}
            onOk={clickResourceModalOkHandler}
            onCancel={clickResourceModalCancelHandler}
          />
      }

      {
        classId && <Breadcrumbs list={BreadcrumbsList} />
      }
      {
        !noFilter && (
          <UiActionBox>
            <UiflexBox>
              <Select
                label="搜尋目標" options={selectInputOptions}
                submitHandler={value => onSubmitHandler(value, 'goal')}
                value={goal}
              />
              <Select
                label="學制" 
                options={educationOptions}
                onChangeHandler={educationChangeHandler}
                value={educationalSystem}
              />
              <Select
                label="科目" 
                options={subjectOptions}
                submitHandler={subjectChangeHandler}
                value={subject}
              />
              <IconInput
                placeholder="搜尋"
                onChange={value => onSubmitHandler(value, 'condition')}
                value={condition}
              />
              <Select
                options={selectOptions}
                value={dateInterval}
                submitHandler={value => onSelectChangeHandler(value)}
                label="課程區間"
              />
              {
                dateInterval === NOW &&
                <>
                  <TimePicker
                    label="選擇課程開始時間"
                    value={startAt}
                    onChange={value => onchangeHandler(value, 'startAt')}
                  />
                  <TimePicker
                    label="選擇課程結束時間"
                    value={endAt}
                    onChange={value => onchangeHandler(value, 'endAt')}
                  />
                </>
              }
            </UiflexBox>
            {classId &&
              <UiButtonBox>
                <Button
                  buttonColor="info"
                  icon='exitToApp'
                  onClick={() => history.goBack()}
                >
                  回到上一頁
                </Button>
                <Button
                  buttonColor='highlight'
                  icon='add'
                  onClick={() => goCreateSession()}>新增課程</Button>
              </UiButtonBox>
            }

          </UiActionBox>
        )
      }
      {
        isLoading ? (
          <Loading />
        ) : (
          sessions.total > 0 ? (
              <Table
                data={formatSessionsData(sessions.data)}
                schema={schema}
                stateShowText={[]}
                changePage_Rows={changePage_Rows}
                totalPage={sessions.total}
                ActionComponents={ActionComponents}
                nowPage={nowPage}
              />
            ) : <UiTableInfo>尚無課程</UiTableInfo>
          )
      }
      <BsModal open={isOpen} title="是否要刪除此課程" onOk={onDeleteSession} onCancel={modalToggle} isLoading={isLoading}>
          確認刪除課程 <span style={{color: '#f00'}}>{`"${targetSession}"`}</span>
      </BsModal>
    </UiTable>
  );
};

SessionManagementTable.propTypes = {
  noFilter: PropTypes.bool,
  defaultDuration: PropTypes.oneOf([ALL, NOW, PROCESSING, PAST, FUTURE, ALL])
};
