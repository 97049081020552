import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton as MatIconButton,
  Avatar as MatAvatar
} from '@material-ui/core';

import { useUser } from 'store/user';
import { ClickablePopoverMenu } from 'components';
import { UiAvatarMenu, UiNickname, UiButtonWrapper } from './AvatarMenu.style';


/**
 * Header 用頭像組件
 */

export const AvatarMenu = () => {
  const [{ profile }, { userLogout }] = useUser();
  const logoutHandler = () => {
    userLogout();
  };

  const goToHome = () => {
    // eslint-disable-next-line max-len
    window.location.href = `https://onelink-chiayi${process.env.REACT_APP_ENV === 'release' ? '' : '-' + process.env.REACT_APP_ENV}.oneclass.com.tw/home`;
  };

  const popoverMenuList = () => {
    const list = [
      { label: '回到班級', func: goToHome, iconName: 'home' },
      { label: '登出', func: logoutHandler, iconName: 'exitToApp' }
    ];
    return list;
  };




  return (
    <UiAvatarMenu>
      {
        profile && profile.id && (
          <ClickablePopoverMenu menuList={popoverMenuList()}>
            <UiButtonWrapper>
              <MatIconButton color="inherit">
                <MatAvatar src={profile.thumbnailUrl || ''} alt={profile.nickname} />
              </MatIconButton>
              <UiNickname>{profile.nickname}</UiNickname>
            </UiButtonWrapper>
          </ClickablePopoverMenu>
        )
      }
    </UiAvatarMenu>
  );
};

AvatarMenu.propTypes = {
  userId: PropTypes.string
};


