import React from 'react';
import { UiFooter, UiRow, UiContent } from './Footer.style';

/**
 * 在這邊描述這個組件
 */

export const Footer = () => {
  return (
    <UiFooter>
      <UiRow>
        <UiContent>v{process.env.REACT_APP_VERSION}</UiContent>
      </UiRow>
    </UiFooter>
  );
};

Footer.propTypes = {

};


