import styled from 'styled-components';
import {
  Toolbar as MatToolbar,
} from '@material-ui/core';

export const UiHeader = styled.div`
	display: block;
`;

export const UiToolbar = styled(MatToolbar)`
	display: flex;
	justify-content: space-between;
	padding-right: 3rem;
	height: 5rem;
	background-color: ${({ theme }) => theme.palette.user.block.bar};

	& > button {
		font-size: 1.2rem;
	}
`;



export const UiToolItem = styled.div`
	display: flex;
	align-items: center;
	padding: 0 0.5rem;
	height: 100%;
	font-size: 1.2rem;
	color: #325b88;
	cursor: pointer;
	background-color: ${({ theme, active }) => active ? theme.palette.user.block.barhover : ''};
`;

export const UiHeaderTitle = styled.h1`

`;


export const UiLogo = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	cursor: pointer;
`;

export const UiToolItemContainer = styled.div`
	display: flex;
	height: 100%;
	font-family: ${({ theme }) => theme.typography.fontFamily};
	flex: 1;
`;
