import styled from 'styled-components';
import { Typography as MatTypography, Divider as MatDivider } from '@material-ui/core';
export const UiLoginForm = styled.div`
	margin: 6rem 0;
	padding: 1rem 0;
	width: 38%;
	min-width: 300px;
	max-width: 700px;

	/* text-align: center; */
	${({ theme }) => theme.shape}
	background-color: #fff;
	border: 1px solid ${({ theme }) => theme.oneClass.border.palette.default};
	box-shadow: 1px 1px 10px #ccc;


	& > form > label {/* checkbox css */
		margin-right: 0;
		margin-left: 0;

		span {
			color: ${({ theme }) => theme.oneClass.text.palette.default};
		}

		& > label {
			margin-right: 0;
			margin-left: 0;

			& > .MuiCheckbox-root {
				padding: 9px 9px 9px 0;
				color: ${({ theme }) => theme.oneClass.text.palette.default};
			}
		}
	}

	& > form > div {
		& > div {
			label {/* input label */
				transform: translate(14px, 14px) scale(1);
				color: ${({ theme }) => theme.oneClass.text.palette.normal};
			}

			& > div {
				& > input {
					padding: 12.5px 14px;
					color: ${({ theme }) => theme.oneClass.text.palette.default} !important;
				}

				& > fieldset {
					border-color: ${({ theme }) => theme.oneClass.border.palette.normal};
				}
			}
		}

		& > button {
			padding: 5px 0;
			width: 40%;
			border-radius: 8px;
		}
	}
`;

export const UiLoginFormFooter = styled.div`
	text-align: center;

	a {
		padding: 0 1rem;
		text-decoration: none;
		color: #333;
	}
`;

export const UiRow = styled.div`
	position: absolute;
	bottom: 0;
	margin: auto;
	width: 100%;
	font-family: ${({ theme }) => theme.typography.fontFamily};
	text-align: center;

	& p {
		font-size: 1rem;
		color: ${({ theme }) => theme.oneClass.text.palette.default};
	}
`;

export const UiContactUs = styled.div`
	margin: auto;
	width: 38%;
	min-width: 300px;
	max-width: 700px;
`;

export const UiIconList = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 1rem auto 0;
	width: 100%;
`;

export const UiIconRow = styled.a`
	display: block;
	width: 33%;
	text-align: center;
	cursor: pointer;
`;


export const UiTypography = styled(MatTypography)`
margin-bottom: 2rem;
width: 100%;
text-align: center;
`;

export const UiDivider = styled(MatDivider)`
`;


